<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Item De Inspección</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Items De Inspección</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-item-inspeccion-export"
                        v-if="$store.getters.can('admin.itemsInspeccion.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-on:click="abrirModal('Agregar', null)"
                        data-toggle="modal"
                        data-target="#Modal_items"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabezera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  id="renderLineas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <!-- Nombre item -->
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <!-- ./ Nombre item -->
                      <!-- Tamaño Tipo de calificación -->
                      <th style="width: 300px">
                        Tipo de Caificación
                        <select
                          name="tipo_control"
                          id="tipo_control"
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_calificacion_novedad"
                          v-on:change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="control in listasForms.tipo_calificacion_novedad"
                            :key="control.numeracion"
                            :value="control.numeracion"
                          >
                            {{ control.descripcion }}
                          </option>
                        </select>
                      </th>
                      <!-- Tipo de control -->
                      <th>
                        Tipo de control
                        <select
                          name="tipo_control"
                          id="tipo_control"
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_control"
                          v-on:change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="control in listasForms.tipo_control"
                            :key="control.numeracion"
                            :value="control.numeracion"
                          >
                            {{ control.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsInspeccion.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td class="text-center">
                        <span
                          :class="
                            item.tipo_calificacion_novedad == 1
                              ? 'badge badge-secondary'
                              : 'badge badge-info'
                          "
                        >
                          {{ item.nTipoCalificacion }}
                        </span>
                      </td>
                      <td>{{ item.nTipoControl }}</td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#Modal_items"
                            v-if="
                              $store.getters.can('admin.itemsInspeccion.show')
                            "
                            v-on:click="abrirModal('Editar', item.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-on:click="destroy(item.id)"
                            v-if="
                              $store.getters.can('admin.itemsInspeccion.delete')
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="itemsInspeccion.total">
                  <p>
                    Mostrando del <b>{{ itemsInspeccion.from }}</b> al
                    <b>{{ itemsInspeccion.to }}</b> de un total:
                    <b>{{ itemsInspeccion.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="itemsInspeccion"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Modal de agregar y editar -->
      <div class="modal fade" id="Modal_items">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <!-- campo Nombre -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="nombre_item">Nombre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre_item"
                      placeholder="Nombre item de inspeccion"
                      v-model="form.nombre"
                      :class="
                        $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <!-- /. campo Nombre -->
                <!-- Campo Tipo de calificacion -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="estado">Tipo de calificación</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipo_calificacion_novedad"
                      :class="
                        $v.form.tipo_calificacion_novedad.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option
                        v-for="calificacion in listasForms.tipo_calificacion_novedad"
                        :key="calificacion.numeracion"
                        :value="calificacion.numeracion"
                      >
                        {{ calificacion.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- /. Campo Tipo de calificacion -->
                <!-- Campo Tipo de control -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="estado">Tipo de control</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipo_control"
                      v-on:change="block_tamano_control(0)"
                      :class="
                        $v.form.tipo_control.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="control in listasForms.tipo_control"
                        :key="control.numeracion"
                        :value="control.numeracion"
                      >
                        {{ control.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- /. Campo Tipo de control -->
                <!-- Campo modal tamaño del control -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="tamano_control">Tamaño Del Control</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="tamano_control"
                      placeholder="Tamaño de control"
                      v-model="form.tamano_control"
                      :class="
                        $v.form.tamano_control.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <!-- /.Campo modal tamaño del control -->
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.form.$invalid"
                v-on:click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
    <ItemInspeccionExport ref="ItemInspeccionExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import ItemInspeccionExport from "./itemInspeccionExport";
import { required, minLength } from "vuelidate/lib/validators";


export default {
  name: "ItemsInspeccion",
  components: {
    pagination,
    ItemInspeccionExport,
  },
  data() {
    return {
      itemsInspeccion: {},
      modal: {
        title: "",
        accion: "",
      },
      filtros: {
        nombre: null,
        tipo_control: null,
        tipo_calificacion_novedad: null,
      },
      listasForms: {
        tipo_control: [],
        tipo_calificacion_novedad: [],
      },
      form: {
        nombre: "",
        tipo_control: "",
        tamano_control: "",
        tipo_calificacion_novedad: "",
      },
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      tipo_control: {
        required,
      },
      tipo_calificacion_novedad: {
        required,
      },
      tamano_control: {
        required,
      },
    },
  },
  methods: {
    //metodo de apertura del modal
    abrirModal(accion, iditem) {
      this.modal.accion = accion;
      this.modal.title = accion + " Items De Inspeccion";
      if (accion == "Editar") {
        this.show(iditem);
      } else {
        this.form = {}; // se limpian los campos de formulario
      }
    },

    getIndex(page = 1) {
      axios
        .get("/api/admin/itemsInspeccion?page=" + page, {
          params: this.filtros /* parametros que se pasan en la ruta */,
        })
        .then((response) => {
          this.itemsInspeccion = response.data;
        });
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios
          .post("/api/admin/itemsInspeccion", this.form)
          .then((response) => {
            document.getElementById("close-modal").click;
            this.form = {};
            this.$refs.closeModal.click();
            this.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async show(idItem) {
      await axios
        .get("/api/admin/itemsInspeccion/show/" + idItem)
        .then((response) => {
          this.form = response.data;
          this.block_tamano_control(this.form.tamano_control);
        });
    },

    destroy(idlinea) {
      this.$swal({
        title: "Esta seguro de eliminar esta Item de inspeccion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/admin/itemsInspeccion/" + idlinea).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item de manera exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    getTipoControl() {
      axios.get("/api/lista/14").then((response) => {
        this.listasForms.tipo_control = response.data;
      });
    },

    getTipoCalificacion() {
      axios.get("/api/lista/56").then((response) => {
        this.listasForms.tipo_calificacion_novedad = response.data;
      });
    },

    block_tamano_control(tamano_control) {
      /* Funcion para bloquear campo cuando es tipo check */
      document.getElementById("tamano_control").disabled = true;
      this.form.tamano_control = tamano_control;
      if (this.form.tipo_control === 2) {
        document.getElementById("tamano_control").disabled = false;
      } else {
        this.form.tamano_control = 0;
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoControl();
    this.getTipoCalificacion();
  },
};
</script>